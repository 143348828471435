<template>
  <div class="flex flex-col w-full mt-6 xl:px-15 lg:px-7 sm-ws:px-10">
    <page-title>Gestion des promoteurs </page-title>
    <table-title nextPath="ajouter" class="sm:flex-row">
      <template v-slot:title>{{ action }} un promoteur</template>
      <template slot="button" slot-scope="{ nextPath }">
        <div
          @click.prevent="nextPath('list-des-teams')"
          class="text-xs tracking-wide capitalize cursor-pointer text-promy-green-300"
        >
          &larr; retour
        </div>
      </template>
    </table-title>
    <hr class="my-6" />
    <div class="xl:pr-24">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <template v-for="component in editedComponents">
          <component
            :is="component"
            :key="component"
            v-model="form"
          ></component>
          <hr class="mt-5" />
        </template>
        <div class="my-12" v-if="$can('edite,gestion_utilisateur')">
          <BaseButton @handleClick="handleSubmit(save)" :loading="loading" />
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Team from './components/Team'
import Entreprise from './components/Entreprise'
import Scoring from './components/Scoring'
import Notaire from './components/Notaire'
import Bancaire from './components/Bancaire'
import Abonnement from './components/Abonnement'
import Documents from './components/Documents/Documents'
import Images from './components/Images'
import MyActivity from './components/MyActivity.vue'
var obj2fd = require('obj2fd/es5').default

export default {
  components: {
    Team,
    Entreprise,
    Scoring,
    Notaire,
    Bancaire,
    Abonnement,
    Documents,
    Images,
    MyActivity,
  },
  data() {
    return {
      loading: false,
      storeURL: `admin/teams`,
      action: 'Ajouter',
      components: [
        'Team',
        'Entreprise',
        'Scoring',
        'Notaire',
        'Bancaire',
        'Abonnement',
        'Documents',
        'Images',
        'MyActivity',
      ],
      error: [],
      form: {
        payement_method: {
          methode_paiement: '',
          paiement_facture: '',
          paiement_bancaire: {
            rib_code_banque: '',
            rib_code_guichet: '',
            rib_numero_compte: '',
            rib_cle_rib: '',
            rib_bic: '',
            rib_iban: '',
            rib_domiciliation: '',
            rib_titulaire: '',
          },
        },
        societe: '',
        logo_path: null,
        kbis_path: null,
        capacite_financiere_globale: '',
        capital_social: '',
        name: '',
        promoteur_id: [],
        reference_nom_des_operation: '',
        references_plans: '',
        refrences_autre: '',
        refrences_plaquettes: '',
        rib_cle_rib: '',
        rib_code_banque: '',
        rib_code_guichet: '',
        rib_domiciliation: '',
        rib_iban: '',
        rib_numero_compte: '',
        rib_titulaire: '',
        sepa: '',
        typologie_de_projets: [],
        notaire: false,
        notaire_adress: '',
        notaire_bat: '',
        notaire_code_postal: '',
        notaire_commune: '',
        notaire_n_voie: '',
        notaire_nom: '',
        notaire_prenom: '',
        departements: [],
        regions: [],
        nations: [],
        notDepartements: [],
        notRegions: [],
        notNations: [],
        checked_nations: [],
        geographique: [],
        no_error_rib: 0,
        no_error_iban: 1,
        siren: '',
        raison_social: '',
        profile: '',
        types: '',
        complement_adress: '',
        n_voie: '',
        commune: '',
        code_postal: '',
        bat: '',
        ville: '',
        scoring: '',
        website: null,
        entreprise: {
          nom: '',
          code_postal: '',
          ville: '',
          site_web: '',
          adresse: '',
        },
        comptabilite: '',
      },
    }
  },
  created() {
    if (this.$route.meta.mode === 'edit') {
      this.storeURL = `admin/teams/${this.$route.params.id}?_method=PUT`
      this.action = 'Editer'
      this.editableTeam(this.$route.params.id)
    } else {
      this.getAbonnementTypes()
    }
  },
  computed: {
    editedComponents() {
      if (!this.$route.params.id) {
        return this.components.filter((component) => component !== 'Documents')
      } else {
        return this.components
      }
    },
  },
  methods: {
    editableTeam(id) {
      this.$http.get(`admin/teams/${id}`).then((res) => {
        this.form = _.merge({}, this.form, res.data.team)

        this.form.notNations = res.data.nations
        this.form.notDepartements = res.data.departements
        this.form.entreprise = res.data.entreprise || {
          nom: '',
          code_postal: '',
          ville: '',
          site_web: '',
          adresse: '',
        }
        this.form.notRegions = res.data.regions
        if (this.form.typologie_de_projets == null) {
          this.form.typologie_de_projets = []
        }
        this.form.payement_method.paiement_bancaire = res.data.team
          .payement_method.paiement_bancaire || {
          rib_code_banque: '',
          rib_code_guichet: '',
          rib_numero_compte: '',
          rib_cle_rib: '',
          rib_bic: '',
          rib_iban: '',
          rib_domiciliation: '',
          rib_titulaire: '',
        }
      })
    },
    getAbonnementTypes() {
      this.$http.get('admin/teams/abonnement-types').then((res) => {
        this.form.notDepartements = res.data.departements
        this.form.notRegions = res.data.regions
      })
    },
    save() {
      this.loading = true
      const form = obj2fd(this.form)

      this.$http
        .post(this.storeURL, form, {
          'content-type': 'multipart/form-data',
        })
        .then(() => {
          this.loading = false
          this.$toaster.success('vous avez ajouté un nouvel team avec succès')
          this.$router.push({
            name: 'list-des-teams-component',
          })
        })
        .catch((err) => {
          this.loading = false
          this.error = err.response.data.errors
        })
    },
  },
}
</script>
